<template>
  <div class="user-logged">
    <div class="balance" @click="onNavDeposit()">
      <div class="balance__icon">
        <BaseImg class="balance__icon" alt="icon-deposit" src="/assets/images/components/desktop/icon-deposit.svg" />
      </div>
      <div class="balance__amount">
        <span>{{ $formatAmountUnit(currentUser?.balance, 1) }} K</span>
      </div>
    </div>
    <div class="group-avatar" @click="openMenuAccount()">
      <BaseImg
        class="avatar"
        alt="user avatar"
        :src="`/assets/images/components/desktop/avatar/${currentUser?.avatar}.webp`"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import { PATH_AVATAR_URL } from '~/constants/path'
import { useAccountStore } from '~~/store/account'
import { useToggleMenu } from '~/composables/useToggleMenu'
import BaseButton from '~/components/common/base-button.vue'
const { $pinia, $formatAmountUnit } = useNuxtApp()
const store = useAppStore($pinia)
const { setDepositFrom } = store
const { currentUser } = storeToRefs(store)
const { toggleMenuAccount, toggleMenuDeposit } = useToggleMenu()

const onNavDeposit = () => {
  setDepositFrom('deposit')
  toggleMenuDeposit()
}

const openMenuAccount = () => {
  toggleMenuAccount()
}

</script>
<style scoped lang="scss" src="assets/scss/components/mobile/header/user-logged.scss" />
